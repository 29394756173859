import { ExtensionPoint } from '@mfl/framework';

import type { HeaderComponent } from './api.types.js';
import { shouldShowLogo } from './header-extentions.js';
export type { HeaderComponent } from './api.types.js';

/**
 * Dynamically inject components to the header (left side)
 */
export const headerComponents = new ExtensionPoint<HeaderComponent>();

/**
 * Dynamically inject components to the right side of the header
 */
export const headerRightComponents = new ExtensionPoint<HeaderComponent>();

/**
 * Announcement components (above the header)
 */
export const headerAnnouncementComponents =
  new ExtensionPoint<HeaderComponent>();

/**
 * Hide/Show the logo\
 * Usually used when going into "drilldown mode" - settings and editor views
 */
export function showLogo(show: boolean) {
  shouldShowLogo.value = show;
}
